




















































































































































































































































































































































import {Component} from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';

import IngredientEntity, {ValidatorRules} from '@/entities/ingredient-entity';
import IngredientRepository from '@/repositories/company/ingredient-repository';

import IngredientItemEntity from "@/entities/ingredient-item-entity";

import StickyHeader from '@/components/StickyHeader.vue';
import NutritionSection from '@/components/Project/nutrition/NutritionSection.vue';
import MadeInAreaSection from './components/MadeInAreaSection.vue';
import GmoCropSelect from './components/ingredient-item-table/GmoCropSelect.vue';
import GmoTypeSelect from './components/ingredient-item-table/GmoTypeSelect.vue';
import AllergenColumn from './components/ingredient-item-table/AllergensColumn.vue';
import AmountSummaryRow from './components/AmountSummaryRow.vue';
import AdditiveSection from "./components/AdditiveSection.vue";

import FooterNote from '../components/FooterNote.vue';
import FooterAttachment from '../components/FooterAttachment.vue';
import FooterHistory from '../components/FooterHistory.vue';
import FooterReferencedProducts from '../components/FooterReferencedProducts.vue';
import TagSelect from '@/components/TagSelect.vue';

import {help as PopoverText} from '@/lang/help/ingredient-create';
import IngredientItemGmoEntity from "@/entities/ingredient-item-gmo-entity";

import {Units} from '@/entities/unit-entities';
import ObjectUtils from "@/utils/object-utils";
import StringUtils from "@/utils/string-utils";

import cloneDeep from 'lodash/cloneDeep';
import CreateLabelPageBase from "@/views/label/companies/CreateLabelPageBase";
import SpecAcceptanceRepository from "@/repositories/spec/company/spec-acceptance-repository";
import {SpecAcceptanceSpecEntity} from "@/entities/specs/spec-acceptance-entity";
import CompanyEntity, {CompanyResourceTagType} from '@/entities/company-entity';
import {FoodType, FoodTypeDict} from "@/entities/specs/spec-entity";
import AdditiveRepository, {
  createAdditiveFormulationList,
  TAdditiveSynonymListItem
} from "@/repositories/master/additive-repository";
import AdditiveSelection from "@/components/Project/Additive/AdditiveSelection.vue";
import {getHrefByRoute} from "@/router";
import {AppTypes} from "@/app-types";
import { CompanyRepository } from '@/repositories/company-repository';
import ResourceDepartmentSelectSection from "@/views/components/Department/ResourceDepartmentSelectSection.vue";
import {AccessLogRepository} from "@/repositories/access-log-repository";
import IconLock from "@/components/Icons/IconLock.vue";
import IconLocked from "@/components/Icons/IconLocked.vue";
import LockButton from "@/views/label/companies/components/LockButton.vue";
import TooltipWarning from "@/components/Tooltip/TooltipWarning.vue";
import {AdditiveType} from "@/entities/additive-entity";
import {dispatchInitialized} from "@/libs/cypress";

@Component({
    components: {
      TooltipWarning,
      LockButton,
      IconLocked,
      IconLock,
      ResourceDepartmentSelectSection,
      AdditiveSelection,
      StickyHeader,
      MadeInAreaSection,
      NutritionSection,
      GmoCropSelect,
      GmoTypeSelect,
      AdditiveSection,
      AllergenColumn,
      AmountSummaryRow,
      FooterNote,
      FooterAttachment,
      FooterHistory,
      FooterReferencedProducts,
      TagSelect
    }
  })
  export default class extends CreateLabelPageBase {
    protected ctor = IngredientEntity;
    protected titleLabel:string = this.$t('原材料');
    protected routePrefix:string = 'ingredient';
    protected model: IngredientEntity = null as any;
    private company: CompanyEntity = null as any;

    private readonly Units = Units;
    private readonly PopoverText = PopoverText;
    private readonly CompanyTagType = CompanyResourceTagType;
    private readonly FoodType = FoodType;
    private readonly FoodTypeDict = FoodTypeDict;

    private additiveSynonymListFormulation:TAdditiveSynonymListItem[] = [];

    private madeInAreaCount:number = 1;

    private specAcceptanceSpecs:SpecAcceptanceSpecEntity[]|null = null;
    private selectedSpecAcceptanceSpecId:number|null = null; // 「反映」するまではモデル側を更新しない
    private get selectedSpecAcceptanceSpec(): SpecAcceptanceSpecEntity|null {
      if(!this.specAcceptanceSpecs || !this.selectedSpecAcceptanceSpecId) return null;
      return this.specAcceptanceSpecs.find(s => s.id === this.selectedSpecAcceptanceSpecId) || null;
    }

    private get rules(){
      return ValidatorRules;
    }

    protected getRepository() {
      return new IngredientRepository(this.companyId);
    }

    protected findById(id): Promise<any> {
      return this.getRepository().findById(id);
    }

    private async created() {
      LoadingHandler(() => {
        return Promise.all([
          new Promise<void>(async (resolve, reject) => {
            this.model = await this.initializeModel() as IngredientEntity;

            if (this.$auth.user.hasValidSpecSubscription && this.model.specReference) {
              (new SpecAcceptanceRepository(this.companyId)).listAcceptanceSpecsOnlyAccepted(this.model.specReference.specAcceptanceSpec.specAcceptanceId).then(res => {
                this.specAcceptanceSpecs = res;
              });
              this.selectedSpecAcceptanceSpecId = this.model.specReference.specAcceptanceSpec.id;
            }

            // first 複合原材料の中身
            if (!this.model.ingredientItems.length) {
              this.addIngredientItemRow();
            }

            if (this.isCreateMode) {
              this.model.departmentIds = this.$auth.user.departments.map(d => d.id);
            } else {
              (new AccessLogRepository(this.companyId)).logIngredient(this.id!);
            }

            if (this.isCloneMode) {
              if (this.model.nutrition) {
                this.model.nutrition.id = null;
                this.model.nutrition.ingredientId = null;
              }
            }

            this.$nextTick(() => {
              resolve();
            })
          }),
          (new CompanyRepository()).find(this.companyId).then((c) => {
            this.company = c;
          })
        ]).then(() => {
          this.isAllLoaded = true;
          dispatchInitialized();
        });
      });

      (new AdditiveRepository().list()).then(list => {
        this.additiveSynonymListFormulation = createAdditiveFormulationList(list);
      });
    }

    private get isReference(): boolean {
      return !!this.model && !!this.model.specReference;
    }

    // region Ingredient Item
    private getIngredientItems() {
      if (!this.model!.ingredientItems.length) return [];
      return this.model!.isFresh ? [this.model!.ingredientItems[0]] : this.model!.ingredientItems;
    }
    private addIngredientItemRow() {
      const newItem = new IngredientItemEntity({}, this.model!);
      if (this.model!.type === FoodType.Additive) {
        newItem.visible = false;
      }
      this.model!.ingredientItems.push(newItem);
    }
    private deleteIngredientItemRow(index: number) {
      if (this.model!.ingredientItems.length <= 1) return;
      this.model!.ingredientItems.splice(index, 1);
    }

    private isSimilarWithAdditiveName(ingredientItemName:string) {
      const list = (this.$refs.additiveSection as AdditiveSection&any).additiveSynonymList;
      const needle = StringUtils.toHankakuKanaLower(ingredientItemName);
      return list
        .filter(s => s.additiveType !== AdditiveType.GeneralFood)
        .some(s => s.synonym === needle);
    }

    // endregion

    // region Gmo for 生鮮食品
    private get gmoCropTypeForFresh() {
      if (!this.model!.ingredientItems[0].ingredientItemGmos.length) return null;
      return this.model!.ingredientItems[0].ingredientItemGmos[0].cropType;
    }
    private set gmoCropTypeForFresh(val) {
      if (val) {
        if (!this.model!.ingredientItems[0].ingredientItemGmos.length) {
          this.addGmoRow(this.model!.ingredientItems[0]);
        }
        this.model!.ingredientItems[0].isGmoProp = true;
        this.model!.ingredientItems[0].ingredientItemGmos[0].cropType = val;
      } else {
        this.model!.ingredientItems[0].isGmoProp = false;
        this.model!.ingredientItems[0].ingredientItemGmos.splice(0);
      }
    }
    private get gmoGmoTypeForFresh() {
      return this.model!.ingredientItems[0].ingredientItemGmos[0].gmoType;
    }
    private set gmoGmoTypeForFresh(val) {
      this.model!.ingredientItems[0].ingredientItemGmos[0].gmoType = val;
    }

    // endregion  Gmo for 生鮮食品

    // region Gmo for 加工食品
    private getItemKeysWhichHaveGmo() {
      return this.model!.ingredientItems.filter((ii:IngredientItemEntity) => ii.isGmoProp).map(ii => ii.rowKey);
    }
    private gmoChanged(ingredientItem:IngredientItemEntity) {
      if (ingredientItem.isGmoProp && ingredientItem.ingredientItemGmos.length === 0) {
        this.addGmoRow(ingredientItem);
      }
    }
    private addGmoRow(ingredientItem:IngredientItemEntity) {
      ingredientItem.ingredientItemGmos.push(new IngredientItemGmoEntity());
    }
    private deleteGmoRow(ingredientItem:IngredientItemEntity, index) {
      if (this.isReference) return;
      if (ingredientItem.ingredientItemGmos.length <= 1) return;
      ingredientItem.ingredientItemGmos.splice(index, 1);
    }
    // endregion Gmo for 加工食品

    private get isAdditivesVisible() {
      return !this.model!.isFresh;
    }

    private getIngredientItemNameRule(item:IngredientItemEntity) {
      return item.isEmpty ? null : this.rules.items.name;
    }
    private getIngredientItemAmountRule(item:IngredientItemEntity) {
      return item.isEmpty ? null : this.rules.items.amount;
    }

    private onTypeChanged(type:FoodType) {
      this.model!.ingredientItems.forEach(ii => {
        if(!ii.name) {
          if (type === FoodType.Additive) {
            ii.visible = false;
          } else {
            ii.visible = true;
          }
        }
      });
    }

    // region Submit
    private async submit() {
      const data = this.serialize();
      await this.submitData(data);
    }

    private serialize(): IngredientEntity {
      const data = cloneDeep(this.model!);

      data.ingredientMadeInAreas.length = this.madeInAreaCount;
      data.ingredientMadeInAreas = data.ingredientMadeInAreas.map(ii => ObjectUtils.excludeObject(ii)) as any;

      data.ingredientItems = data.ingredientItems.map(ii => {
        const ingredientItem = ObjectUtils.excludeObject(ii, ['ingredientItemGmos']) as any;

        if (!ingredientItem.isGmoProp) {
          ingredientItem.ingredientItemGmos = [];
        }
        return ingredientItem;
      });

      if (this.model!.isFresh) {
        data.ingredientItems[0].name = data.displayName!;
        data.ingredientItems[0].amount = 100;
        data.ingredientItems = [data.ingredientItems[0]];
        data.ingredientDisplaySetting.isIngredientItemAmountPercent = true;
        data.ingredientAdditives = [];
      } else {
        data.ingredientItems = data.ingredientItems.filter(ii => !IngredientItemEntity.isEmptyStatic(ii));
        data.ingredientAdditives = this.model!.ingredientAdditives.filter(ia => !ia.isEmpty)
          .map(ia => {
            return {
              id: ia.id,
              additiveSynonymId: ia.additiveSynonymId,
              additivePurposeSynonymId: ia.additivePurposeSynonymId,
              amount: ia.amount,
              visible: ia.visible,
              originAllergenIds: ia.originAllergenIds,
            } as any;
          });
      }

      data.nutrition = data.nutrition.serialize();

      data.attachments = data.attachments.filter(a => a.isAdded || a.__destroy);

      return data;
    }
    // endregion

    // region Spec
    private importFromSpec() {
      LoadingHandler(async () => {
        const newModel = await (new SpecAcceptanceRepository(this.companyId))
          .makeIngredient(this.selectedSpecAcceptanceSpecId!);

        this.model = Object.assign(newModel, {
          name: this.model.name,
          displayName: this.model.displayName,
          intraCode: this.model.intraCode,
          tags: this.model.tags,
          costPerKg: this.model.costPerKg,
          yieldPercent: this.model.yieldPercent,
          unit: this.model.unit,
          amountPerUnit: this.model.amountPerUnit,
          departmentIds: this.model.departmentIds,
        });

        this.$message({type: 'info', message: this.$t('反映しました（まだ保存されていません）')});

        this.$nextTick(() => {
          if (this.$refs.nutritionSection) {
            (this.$refs.nutritionSection as InstanceType<typeof NutritionSection>).setSelectedNutrition(this.model!.nutrition.referredNutritionId);
          }
          if (this.$refs.additiveSection) {
            (this.$refs.additiveSection as InstanceType<typeof AdditiveSection>).init();
          }
          if (this.$refs.madeInAreaSection) {
            (this.$refs.madeInAreaSection as InstanceType<typeof MadeInAreaSection>).initCount();
          }
        });
      });
    }
    private getSelectedSpecAcceptanceSpecLink() {
      const s  = this.selectedSpecAcceptanceSpec;
      if (!s) return null;
      const loc = s.isSelfSubmission
        ? { name: 'self-submission.show', params: { selfSubmissionId: s.selfSubmissionId } }
        : { name: 'acceptance.show', params: { specAcceptanceId: s.specAcceptanceId, specAcceptanceSpecId: s.id } };
      return getHrefByRoute(AppTypes.Spec, loc as any).href;
    }
    // endregion Spec

}
